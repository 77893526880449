/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/**
 * Basic body style
 */
body {
  width: 100%;
  height: 100dvh;
  color: whitesmoke;
  background-color: black;
}

/**
 * Basic styles for links
 */
a {
  text-decoration: none;
}

/**
 * basic style for button
 */
button {
  cursor: pointer;
  border: 0;
  outline: none;
  border-style: none;
}
